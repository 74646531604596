import * as React from "react";
import IFileStatus from "../IFileStatus";
import { ACTIONS, reducer, reducerInitState } from "./reducer";
import ModalUploads from "../ModalUploads";
import { createUseStyles } from "react-jss";
import { getSignedUrl, mcrConfirm } from "../../../api/fetchesApiMediaLibrary";
const useStyles = createUseStyles({
    multiUploads: {
        bottom: 75,
    },
});
const MultiUploads = ({ uploads }) => {
    const classes = useStyles({});
    const [state, dispatch] = React.useReducer(reducer, reducerInitState);
    const { expanded, items, msgStopUpload } = state;
    const onPutS3File = React.useCallback(async (file, data) => {
        const { fileId } = data;
        await mcrConfirm(fileId);
    }, []);
    const isUploading = !!items.find((el) => {
        const fSet = new Set([IFileStatus.Uploading, IFileStatus.Waiting]);
        return fSet.has(el.status);
    });
    const onStopUploads = React.useCallback(() => {
        dispatch({ type: ACTIONS.RESET });
    }, []);
    const onContinue = React.useCallback(() => {
        dispatch({ type: ACTIONS.MSG_CLOSE });
    }, []);
    const onUpdateFileStatus = React.useCallback((index, ifile) => {
        dispatch({
            type: ACTIONS.SET_ITEMS,
            ifile,
        });
    }, []);
    const onCloseUploads = React.useCallback(() => {
        if (isUploading) {
            dispatch({ type: ACTIONS.MSG_OPEN });
        }
        else {
            dispatch({ type: ACTIONS.RESET });
        }
    }, [isUploading]);
    const toggleExpanded = React.useCallback(() => {
        dispatch({ type: ACTIONS.EXPANDED });
    }, []);
    React.useEffect(() => {
        if (!!uploads.length) {
            dispatch({
                type: ACTIONS.ADD_ITEMS,
                uploads,
            });
        }
    }, [uploads]);
    if (!items.length)
        return null;
    return (React.createElement(ModalUploads, { className: classes.multiUploads, items: items, getSignedUrl: getSignedUrl(), onPutS3File: onPutS3File, uploadRequestHeaders: {}, expanded: expanded, onCloseToolbar: onCloseUploads, onExpand: toggleExpanded, onUpdateFileStatus: onUpdateFileStatus, msgStopUpload: msgStopUpload, onContinue: onContinue, onStopUploads: onStopUploads }));
};
export default MultiUploads;
