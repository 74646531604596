import * as React from "react";
import { createUseStyles } from "react-jss";
import Btn from "@warda/library-ui/core/Btn";
import { THEME_COLORS_SOFT, THEME_COLORS_DARK, getTheme, } from "@warda/library-ui/theme";
import classnames from "classnames";
const useStyles = createUseStyles({
    blackContainer: {
        margin: "0 2px",
        borderRadius: getTheme().borderRadius,
        backgroundColor: "transparent",
    },
    needIconBlack: {
        color: THEME_COLORS_SOFT.typography,
    },
    needIconWhite: {
        color: THEME_COLORS_DARK.typography,
    },
});
const BtnBlack = (p) => {
    const classes = useStyles({});
    return (React.createElement("div", { className: classes.blackContainer, style: p.style },
        React.createElement(Btn, { ...p, style: { margin: 0 }, className: "", iconClassName: classnames({
                [classes.needIconWhite]: p.isDark === true,
                [classes.needIconBlack]: p.isDark === false,
            }) })));
};
export default BtnBlack;
