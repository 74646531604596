import * as React from "react";
import { getTheme } from "@warda/library-ui/theme";
import { createUseStyles } from "react-jss";
import Text from "@warda/library-ui/core/Text";
import { getActiveFilters, getSlcSaveValues, } from "./utils";
import classnames from "classnames";
import Link from "@warda/library-ui/core/Link";
import { CtxDispatchFilters } from "./contexts";
import { ACT_PANEL } from "./reducer";
import getCtlgId from "../AreaProducts/getCtlgId";
import { updateSetsAfterModify, } from "@warda/library-ui/core/WindowMngSets";
import BorderShadow from "@warda/library-ui/core/BorderShadow";
import { CtxUserPref } from "../contexts";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import PanelFiltersListitem from "./PanelFiltersListitem";
import mixColors from "@warda/library-ui/utils/mixColors";
import getMngSetsSelected from "../../utils/getMngSetsSelected";
import { onUpdateSet } from "../../api/fetchesApiJsonStore";
const useStyles = createUseStyles({
    list: {
        position: "relative",
        flex: 1,
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
    },
    listScroll: {
        flex: 1,
        display: "flex",
        flexDirection: "column",
        paddingBottom: 50,
        overflow: "overlay",
    },
    typo: {
        opacity: 0.75,
        fontStyle: "italic",
        textAlign: "center",
    },
    listDragging: {
        backgroundColor: mixColors(0.25, getTheme().colors.background, getTheme().colors.theme1),
    },
});
const PanelFiltersList = ({ className, style, catalogs = [], columns = [], filters = [], mngSets, mngSetsContext, mngSetsOnChange, onFilterValue, onFilterCount, searchEs, overrideMapId = {}, overrideMapType = {}, slcCtlg: slcCtlgId, slcLang, }) => {
    const userPref = React.useContext(CtxUserPref);
    const dispatchPanel = React.useContext(CtxDispatchFilters);
    const [scroll, setScroll] = React.useState(false);
    const classes = useStyles({});
    const slcCtlg = getCtlgId(catalogs, slcCtlgId);
    const { slcSetId, slcSet } = getMngSetsSelected(mngSetsContext, userPref, mngSets);
    const isDragDisabled = !mngSets || !slcSet || filters.length === 0;
    const activeFilters = getActiveFilters({
        preferences: userPref,
        context: mngSetsContext,
        mngSets,
        filters,
    });
    const onManagerToggle = React.useCallback(() => {
        dispatchPanel({ type: ACT_PANEL.OPEN_MANAGER, open: true });
    }, [dispatchPanel]);
    const onScroll = React.useCallback((e) => {
        setScroll(e.target.scrollTop > 0);
    }, []);
    const onDragEnd = React.useCallback((result) => {
        if (!result.destination)
            return null;
        const dragIndexStart = result.source.index;
        const dragIndexEnd = result.destination.index;
        const newSetItems = Array.from(slcSet.payload.items);
        const [removed] = newSetItems.splice(dragIndexStart, 1);
        newSetItems.splice(dragIndexEnd, 0, removed);
        const newSet = {
            ...slcSet,
            payload: {
                ...slcSet.payload,
                items: newSetItems,
            },
        };
        const newSets = updateSetsAfterModify(mngSets, newSet);
        onUpdateSet(mngSetsContext, slcSetId, newSet.payload);
        mngSetsOnChange({
            context: mngSetsContext,
            slcId: slcSetId,
            newId: false,
            sets: newSets,
            keepValue: getSlcSaveValues(),
            updateFilters: false,
        });
    }, [mngSets, mngSetsContext, mngSetsOnChange, slcSet, slcSetId]);
    return (React.createElement("div", { style: style, className: classnames({
            [classes.list]: true,
            [className]: !!className,
        }), onScroll: onScroll },
        React.createElement(DragDropContext, { onDragEnd: onDragEnd },
            React.createElement(Droppable, { droppableId: "panelfilters-dragndrop", isDropDisabled: isDragDisabled }, (droppableProvided, droppableSnapshot) => (React.createElement("div", { className: classnames({
                    [classes.listScroll]: true,
                    [classes.listDragging]: droppableSnapshot.isDraggingOver,
                }), onScroll: onScroll, ref: droppableProvided.innerRef, ...droppableProvided.droppableProps },
                !scroll ? null : React.createElement(BorderShadow, { position: "top" }),
                !!mngSets && !activeFilters.length ? (React.createElement(React.Fragment, null,
                    React.createElement(Text, { style: { marginTop: 40 }, className: classes.typo, children: "There are no filters selected" }),
                    React.createElement(Text, { style: { margin: "0 30px" }, className: classes.typo, children: "Select one or more filters from" }),
                    React.createElement(Link, { onClick: onManagerToggle, children: "Your Filters Sets" }))) : null,
                activeFilters.map((f, index) => (React.createElement(Draggable, { key: f.id, draggableId: f.id, index: index, isDragDisabled: isDragDisabled }, (draggableProvided, draggableSnapshot) => (React.createElement(PanelFiltersListitem, { catalogs: catalogs, columns: columns, onFilterValue: onFilterValue, onFilterCount: onFilterCount, searchEs: searchEs, overrideMapId: overrideMapId, overrideMapType: overrideMapType, slcCtlg: slcCtlg, slcLang: slcLang, filter: f, dragProvided: draggableProvided, dragSnapshot: draggableSnapshot, isDraggingOver: droppableSnapshot.isDraggingOver, isDragDisabled: isDragDisabled }))))),
                droppableProvided.placeholder))))));
};
export default PanelFiltersList;
