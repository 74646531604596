import * as React from "react";
import ViewLoadMore from "../ViewLoadMore";
import ViewLoadedCount from "../ViewLoadedCount";
import { needPagination } from "../utils";
const ViewListFooter = ({ type, prnt, files, filesTotal, sortKey, sortOrd, filters, filtersCondition, indexRow, }) => {
    const { pagination } = needPagination({
        type,
        files,
        filesTotal,
    });
    if (!pagination)
        return null;
    if (indexRow > 0)
        return null;
    return (React.createElement(React.Fragment, null,
        React.createElement(ViewLoadedCount, { type: type, files: files, filesTotal: filesTotal, style: { margin: "0px 15px" } }),
        React.createElement("div", { style: { flex: 1 } }),
        React.createElement(ViewLoadMore, { type: type, prnt: prnt, sortKey: sortKey, sortOrd: sortOrd, filters: filters, filtersCondition: filtersCondition, style: { margin: "0px 15px" } })));
};
export default ViewListFooter;
