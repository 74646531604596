export const HTML_ID_DRAWERS = "portalIdDrawers";
export const DRAWER_WIDTH = 400;
export const getAccessCode = () => {
    const query = new URLSearchParams(window.location.search);
    return query.get("token");
};
export const getTenantId = () => {
    const query = new URLSearchParams(window.location.search);
    return query.get("tenantId");
};
export var ATTRIBUTES_KEY;
(function (ATTRIBUTES_KEY) {
    ATTRIBUTES_KEY["NAME"] = "name";
    ATTRIBUTES_KEY["SIZE"] = "sizeInBytes";
    ATTRIBUTES_KEY["TYPE"] = "mimeType";
    ATTRIBUTES_KEY["CREATED"] = "created";
})(ATTRIBUTES_KEY || (ATTRIBUTES_KEY = {}));
export const ATTRIBUTES = [
    {
        id: ATTRIBUTES_KEY.NAME,
        label: "Name",
        width: 250,
        sortable: true,
    },
    {
        id: ATTRIBUTES_KEY.CREATED,
        label: "Created",
        width: 150,
        sortable: true,
    },
    {
        id: ATTRIBUTES_KEY.SIZE,
        label: "Size",
        width: 150,
        sortable: false,
    },
    {
        id: ATTRIBUTES_KEY.TYPE,
        label: "Extension",
        width: 80,
        sortable: false,
    },
];
