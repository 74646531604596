import IFileStatus from "../IFileStatus";
import { CURRENT_FOLDER_TO_UPLOAD } from "./reducer";
import { getPathArray, SEPARATOR } from "./utils";
const itemsUploading = ({ items, relativePathsFolderId, numParallelUpload = 1, }) => {
    const newItems = [].concat(items);
    newItems.forEach((itemToAdd) => {
        var _a;
        const canOtherUpdating = newItems.filter(({ status }) => status === IFileStatus.Uploading).length <
            numParallelUpload;
        const isAvailableFolderId = ((_a = relativePathsFolderId.find(({ name: folderName }) => {
            const c1 = itemToAdd.file.webkitRelativePath === "";
            const c2 = folderName === CURRENT_FOLDER_TO_UPLOAD;
            const folderPath = getPathArray(itemToAdd.file.webkitRelativePath).join(SEPARATOR);
            return (c1 && c2) || folderName === folderPath;
        })) === null || _a === void 0 ? void 0 : _a.folderId) !== undefined;
        if (isAvailableFolderId &&
            canOtherUpdating &&
            itemToAdd.status === IFileStatus.Waiting) {
            itemToAdd.status = IFileStatus.Uploading;
        }
    });
    return newItems;
};
export default itemsUploading;
