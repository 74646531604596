import getAttributeKey from "../getAttributeKey";
import getCtlgId, { getLangId } from "../getCtlgId";
import { inScope } from "../PanelDetail/filterColumns";
export const getGridSorts = ({ sorts, columns, catalogs, slcCtlg, slcLang, }) => {
    return sorts.filter(({ id }) => {
        const column = columns.find((c) => c.id === id);
        return canSort({ catalogs, column, slcCtlg, slcLang });
    });
};
export const canSort = ({ catalogs, column, slcCtlg = "", slcLang = "", }) => {
    const { multiCatalog, multiLanguage, sortable } = column;
    if (sortable === false)
        return false;
    const ctlgId = getCtlgId(catalogs, slcCtlg);
    const langId = getLangId(catalogs, slcCtlg, slcLang);
    if (multiCatalog && !ctlgId)
        return false;
    if (multiLanguage && !langId)
        return false;
    if (!inScope(column, [slcCtlg]))
        return false;
    return true;
};
export const getSort = ({ sorts, catalogs = [], columns, slcCtlg = "", slcLang = "", }) => {
    return sorts
        .filter(({ id }) => {
        const column = columns.find((c) => c.id === id);
        return canSort({ catalogs, column, slcCtlg, slcLang });
    })
        .map(({ id, order }) => {
        const column = columns.find((c) => c.id === id);
        const key = getAttributeKey(column, slcCtlg, slcLang);
        return { [key]: { order } };
    });
};
