import itemsAdd from "../utils/itemsAdd";
import itemsUploading from "./itemsUploading";
const itemsAddAndUploading = ({ items, filesToAdd, maxSize, numParallelUpload = 1, }) => {
    const itemsAdded = itemsAdd({
        items,
        filesToAdd,
        maxSize,
    });
    const result = itemsUploading({ items: itemsAdded, numParallelUpload });
    return result;
};
export default itemsAddAndUploading;
