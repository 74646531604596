import * as React from "react";
import Toolbar from "@warda/library-ui/core/Toolbar";
import SelectActions from "./SelectActions";
import BtnNavigateBack from "./Buttons/BtnNavigateBack";
import BtnBreadcrumb from "./Buttons/BtnBreadcrumb";
import BtnSort from "./Buttons/BtnSort";
import BtnView from "./Buttons/BtnView";
const ToolbarActions = ({ loading, filesSlcId, folderPath, sortKey, sortOrd, view, }) => {
    if (loading)
        return null;
    return (React.createElement(Toolbar, null, filesSlcId.length === 0 ? (React.createElement(React.Fragment, null,
        React.createElement(BtnNavigateBack, { folderPath: folderPath }),
        React.createElement(BtnBreadcrumb, { folderPath: folderPath }),
        React.createElement("div", { style: { flex: 1 } }),
        React.createElement(BtnSort, { sortKey: sortKey, sortOrd: sortOrd }),
        React.createElement(BtnView, { view: view }))) : (React.createElement(SelectActions, { filesSlcId: filesSlcId }))));
};
export default ToolbarActions;
