import * as React from "react";
import { ACT_VPORT } from "../reducer";
import ViewList from "./ViewList";
import ViewBlock from "./ViewBlock";
import { SegmentedViewType } from "@warda/library-ui/core/SegmentedView";
import { CtxDispatchViewport } from "../contexts";
import PlaceholderError from "@warda/library-ui/core/PlaceholderError";
import Placeholder from "@warda/library-ui/core/Placeholder";
const placeholderIcon = "folder_open";
const placeholderLabel = "THIS FOLDER IS EMPTY";
const PlaceholderCase = ({ text }) => {
    switch (text) {
        case "deleted":
            return (React.createElement(Placeholder, { icon: "folder_delete", label: "This folder has been deleted" }));
        default:
            return React.createElement(PlaceholderError, { title: text });
    }
};
const View = ({ searchTime, error, prnt, view, files, filesSlcId, filesTotal, loading, sortKey, sortOrd, filters, filtersCondition, }) => {
    const dispatchViewport = React.useContext(CtxDispatchViewport);
    const cbOnViewClick = React.useCallback(() => {
        dispatchViewport({ type: ACT_VPORT.DESELECT_FILES });
    }, [dispatchViewport]);
    return (React.createElement("div", { style: {
            flex: 1,
            position: "relative",
            display: "flex",
            flexDirection: "column",
        } },
        !error ? null : React.createElement(PlaceholderCase, { text: error }),
        error || view !== SegmentedViewType.BLOCK ? null : (React.createElement(ViewBlock, { searchTime: searchTime, prnt: prnt, loading: loading, files: files, filesTotal: filesTotal, filesSlcId: filesSlcId, onClick: cbOnViewClick, sortKey: sortKey, sortOrd: sortOrd, placeholderIcon: placeholderIcon, placeholderLabel: placeholderLabel, filters: filters, filtersCondition: filtersCondition })),
        error || view !== SegmentedViewType.LIST ? null : (React.createElement(ViewList, { searchTime: searchTime, prnt: prnt, loading: loading, files: files, filesTotal: filesTotal, filesSlcId: filesSlcId, onClick: cbOnViewClick, sortKey: sortKey, sortOrd: sortOrd, placeholderIcon: placeholderIcon, placeholderLabel: placeholderLabel, filters: filters, filtersCondition: filtersCondition }))));
};
export default View;
