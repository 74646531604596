import * as React from "react";
import { Collapse } from "@warda/library-ui/core/Transitions";
import Divider from "@warda/library-ui/core/Divider";
import ListitemFile from "./ListitemFile";
import { FixedSizeList } from "react-window";
import { IFileStatus } from "..";
import S3Upload from "../utils/S3Upload";
import getMimeType from "../utils/getMimeType";
import { ERROR_GENERIC } from "../../../constants";
const getErrorText = async (err) => {
    try {
        const errJson = await err.response.json();
        return errJson.error || errJson.message;
    }
    catch {
        return ERROR_GENERIC;
    }
};
const renderRow = (props) => {
    const { files, fileState } = props.data;
    const { index, style } = props;
    const obj = files[index];
    const { uploadProgress, puttingS3 } = fileState[props.index] || {
        uploadProgress: 0,
        puttingS3: false,
    };
    return (React.createElement(ListitemFile, { style: style, file: obj.file, fileStatus: obj.status, tooltip: obj.tooltip, uploadProgress: uploadProgress, puttingS3: puttingS3 }));
};
var ACTIONS;
(function (ACTIONS) {
    ACTIONS["UPLOAD_PROGRESS"] = "UPLOAD_PROGRESS";
    ACTIONS["PUTTING_S3"] = "PUTTING_S3";
})(ACTIONS || (ACTIONS = {}));
const initialState = {
    fileState: [],
};
const reducer = (state, action) => {
    const newState = { ...state };
    switch (action.type) {
        case ACTIONS.UPLOAD_PROGRESS: {
            const { index, uploadProgress } = action;
            const { puttingS3 } = newState.fileState[index] || {
                puttingS3: false,
            };
            const { uploadProgress: prevUploadProgress } = newState.fileState[index] || {
                uploadProgress: 0,
            };
            newState.fileState[index] = {
                puttingS3,
                uploadProgress: Math.max(prevUploadProgress, uploadProgress),
            };
            return newState;
        }
        case ACTIONS.PUTTING_S3: {
            const { index, puttingS3 } = action;
            const { uploadProgress } = newState.fileState[index] || {
                uploadProgress: 0,
            };
            newState.fileState[index] = {
                puttingS3,
                uploadProgress,
            };
            return newState;
        }
        default:
            return state;
    }
};
const List = ({ expanded, files, getSignedUrl, signingUrlMethod, onPutS3File, onUpdateFileStatus, uploadRequestHeaders, }) => {
    const [{ fileState }, dispatch] = React.useReducer(reducer, initialState);
    const S3UploadRef = React.useRef([]);
    const onUpdate = React.useCallback((index, ifile) => {
        onUpdateFileStatus(index, ifile);
        S3UploadRef.current[index] = undefined;
    }, [onUpdateFileStatus]);
    const getSignedUrlCb = React.useCallback((index, ifile) => async (item, callback) => {
        try {
            const res = await getSignedUrl(ifile);
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            if (!res.fileId)
                throw res;
            callback(res);
        }
        catch (err) {
            console.log("[getSignedUrlCb] ", index, ifile);
            const tooltip = await getErrorText(err);
            const ifileUpdate = {
                ...ifile,
                tooltip,
                status: IFileStatus.Error,
            };
            onUpdate(index, ifileUpdate);
        }
    }, [getSignedUrl, onUpdate]);
    const onUploadFinishS3Put = React.useCallback((index, ifile) => async (data) => {
        let tooltip = "";
        if (onPutS3File) {
            dispatch({ type: ACTIONS.PUTTING_S3, puttingS3: true, index });
            try {
                await onPutS3File(ifile, data);
            }
            catch (err) {
                console.log("[onUploadFinishS3Put] ", index, ifile);
                tooltip = await getErrorText(err);
            }
            dispatch({ type: ACTIONS.PUTTING_S3, puttingS3: false, index });
        }
        const ifileUpdate = {
            ...ifile,
            tooltip,
            status: !!tooltip ? IFileStatus.Error : IFileStatus.Completed,
        };
        onUpdate(index, ifileUpdate);
    }, [onPutS3File, onUpdate]);
    const onUploadError = React.useCallback((index, ifile) => (error) => {
        console.log("[onUploadError] ", { index, ifile, error });
        const ifileUpdate = {
            ...ifile,
            status: IFileStatus.Error,
            tooltip: "Unable upload this file, please check it and try again.",
        };
        onUpdate(index, ifileUpdate);
    }, [onUpdate]);
    const onUploadAbort = React.useCallback((index, ifile) => () => {
        console.log("[onUploadAbort] ", { index, ifile });
        const ifileUpdate = {
            ...ifile,
            status: IFileStatus.Abort,
            tooltip: "Upload canceled",
        };
        onUpdate(index, ifileUpdate);
    }, [onUpdate]);
    const onUploadProgress = React.useCallback((index) => (uploadProgress) => {
        dispatch({ type: ACTIONS.UPLOAD_PROGRESS, uploadProgress, index });
    }, []);
    React.useEffect(() => {
        for (let i = 0; i < files.length; i++) {
            const cF = files[i];
            const { status: fileStatus, file } = cF;
            if (fileStatus === IFileStatus.Uploading && !S3UploadRef.current[i]) {
                const { type, name } = file;
                const mimeType = getMimeType(name, type);
                S3UploadRef.current[i] = new S3Upload({
                    file,
                    mimeType,
                    getSignedUrl: getSignedUrlCb(i, cF),
                    server: undefined,
                    onFinishS3Put: onUploadFinishS3Put(i, cF),
                    onProgress: onUploadProgress(i),
                    onError: onUploadError(i, cF),
                    onAbort: onUploadAbort(i, cF),
                    signingUrlMethod: signingUrlMethod,
                    uploadRequestHeaders: uploadRequestHeaders,
                    contentDisposition: "auto",
                });
            }
        }
    }, [
        files,
        getSignedUrlCb,
        onUploadFinishS3Put,
        onUploadProgress,
        onUploadError,
        onUploadAbort,
        signingUrlMethod,
        uploadRequestHeaders,
    ]);
    React.useEffect(() => {
        return () => {
            if (!!S3UploadRef && S3UploadRef.current.length !== 0) {
                // eslint-disable-next-line react-hooks/exhaustive-deps
                for (const uploader of S3UploadRef.current)
                    uploader === null || uploader === void 0 ? void 0 : uploader.abortUpload();
            }
        };
    }, []);
    return (React.createElement(Collapse, { open: expanded },
        React.createElement(React.Fragment, null,
            React.createElement(Divider, null),
            React.createElement(FixedSizeList, { itemSize: 40, height: 140, itemCount: files.length, itemData: {
                    files,
                    fileState,
                }, width: "100%", children: renderRow }))));
};
export default List;
