import { FILTERS_STICKY, KEY_CATALOG, KEY_ENTITY_TYPE, PREF_KEY, } from "../../constantsKey";
import { getFilterCtlgValue } from "../AreaProducts/AreaLayoutGrid/PanelOptions/SelectCtlg/options";
import { getFilterTypeValue } from "../AreaProducts/AreaLayoutGrid/PanelOptions/SelectLevel/options";
import { isTextFiltered, clearTextFilter, isPercentageFiltered, isSelectFiltered, isBooleanFiltered, isDateFiltered, DefaultPercentageType, DefaultSelectType, DefaultTextType, DefaultBooleanType, DefaultDateType, } from "@warda/library-ui/core/Filter";
import getMngSetsSelected from "../../utils/getMngSetsSelected";
import localstorage, { localstorageGetItem, } from "@warda/library-ui/localstorage";
import { KEEP_FILTERS_VALUES } from "../../constantsLocalStorage";
export const FilterTypePercentage = DefaultPercentageType;
export const FilterTypeBoolean = DefaultBooleanType;
export const FilterTypeDate = DefaultDateType;
export const FilterTypeText = DefaultTextType;
export const FilterTypeMultiSelection = DefaultSelectType;
export const FilterTypeSingleSelection = "SELECTION";
export const FilterTypeUser = "USERSELECTION";
export const FilterTypeDictionary = "DICTIONARY";
export const FilterTypeCategory = "CATEGORY";
export const updateFilters = (fNew, filters) => {
    return filters.map((fOld) => {
        if (fOld.id === fNew.id) {
            return { ...fOld, ...fNew };
        }
        return fOld;
    });
};
export const isMultCatalogLanguage = ({ multiCatalog, multiLanguage, ctlgId, langId, }) => {
    if (multiCatalog)
        return !!ctlgId;
    if (multiLanguage)
        return !!langId;
    return true;
};
export const getFilterAttrSubLabel = (f, catalogs, slcCtlg, slcLang) => {
    if (!f)
        return "";
    const disabled = getFilterAttrDisabled(f, catalogs, slcCtlg, slcLang);
    const catalog = catalogs.find((c) => c.id === slcCtlg);
    if (catalogs.length === 1 || disabled || !f.multiCatalog || !catalog) {
        return "";
    }
    return (`Searching in ${catalog.displayName}` +
        (f.multiLanguage ? ` - ${slcLang.toLocaleUpperCase()}` : ""));
};
export const getActiveFilters = ({ preferences, context, mngSets, filters, }) => {
    if (!mngSets)
        return filters; // sezioni senza sets, tipo "Roles"
    const { slcSet } = getMngSetsSelected(context, preferences, mngSets);
    if (!slcSet)
        return [];
    return slcSet.payload.items
        .map((f) => f.id)
        .map((id) => filters.find((f) => f.id === id))
        .filter((f) => !!f)
        .filter(({ id }) => !FILTERS_STICKY.has(id))
        .filter((f) => f.type !== FilterTypeCategory);
};
export const decorateFiltersValuesWithPref = ({ context, mngSets, filters, catalogs = [], preferences, }) => {
    const { slcSetId, slcSet } = getMngSetsSelected(context, preferences, mngSets);
    if (!slcSet)
        return filters;
    const filtersNew = filters.map((f) => {
        if (f.id === KEY_CATALOG) {
            return {
                ...f,
                value: getFilterCtlgValue(catalogs, preferences[PREF_KEY.filterCtlg], preferences[PREF_KEY.filterLang]).value,
            };
        }
        if (f.id === KEY_ENTITY_TYPE) {
            return {
                ...f,
                value: getFilterTypeValue(f.options, preferences[PREF_KEY.filterType]),
            };
        }
        return f;
    });
    return decorateFiltersValuesWithSets({
        filters: filtersNew,
        mngSets,
        mngSetsSlcId: slcSetId,
        mngKeepValue: getSlcSaveValues(),
    });
};
export const decorateFiltersValuesWithSets = ({ filters, mngSets, mngSetsSlcId, mngKeepValue, }) => {
    const slcSet = mngSets.find((s) => s.id === mngSetsSlcId);
    if (!mngSetsSlcId || !slcSet)
        return filters;
    return filters.map((f) => {
        if (FILTERS_STICKY.has(f.id))
            return f;
        if (f.type === FilterTypeCategory)
            return f;
        const fClear = clearFiltersAttr([f])[0];
        const fSaved = slcSet.payload.items.find((c) => c.id === f.id);
        if (!fSaved)
            return fClear;
        if (!mngKeepValue)
            return fClear;
        return {
            ...fClear,
            ...fSaved,
        };
    });
};
export const getFilterSetData = (f, keepValue = true) => {
    if (!keepValue)
        return { id: f.id };
    if (f.type === FilterTypeText) {
        return {
            id: f.id,
            switchCaseSensitive: f.switchCaseSensitive,
            switchExactValue: f.switchExactValue,
            switchNoValue: f.switchNoValue,
            value: f.value,
        };
    }
    return { id: f.id, value: f.value };
};
export const areFiltersUseful = (filters) => {
    return !!filters
        .filter(({ id }) => !FILTERS_STICKY.has(id))
        .find((f) => {
        switch (f.type) {
            case FilterTypePercentage:
                return isPercentageFiltered(f);
            case FilterTypeBoolean:
                return isBooleanFiltered(f);
            case FilterTypeDate:
                return isDateFiltered(f);
            case FilterTypeText:
                return isTextFiltered(f);
            case FilterTypeMultiSelection:
            case FilterTypeSingleSelection:
            case FilterTypeUser:
            case FilterTypeDictionary:
            case FilterTypeCategory:
                return isSelectFiltered(f);
            default:
                return false;
        }
    });
};
export const getFilterAttrDisabled = (f, catalogs, slcCtlg, slcLang) => {
    if (!f)
        return true;
    if (catalogs.length === 1)
        return false;
    return !!((f.multiCatalog && !slcCtlg) || (f.multiLanguage && !slcLang));
};
export const getFilterAggregation = ({ id, // string
type, // FilterType
keyword, // boolean
sortId, // string
multiCatalog, // boolean
multiLanguage, // boolean
nested, // boolean
attributeStructureId, //string;
isFlat, // boolean
 }) => ({
    id,
    type,
    keyword,
    sortId,
    multiCatalog,
    multiLanguage,
    nested,
    attributeStructureId,
    isFlat,
});
export const clearFiltersAttr = (filters) => {
    return filters.map((filter) => {
        switch (filter.type) {
            case FilterTypeText:
                return clearTextFilter(filter);
            default:
                return { ...filter, value: undefined };
        }
    });
};
export const getSlcSaveValues = () => {
    return localstorageGetItem(KEEP_FILTERS_VALUES, false);
};
export const setSlcSaveValues = (v) => {
    localstorage.setItem(KEEP_FILTERS_VALUES, v);
};
