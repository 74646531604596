import IFileStatus from "../IFileStatus";
import isEmpty from "lodash-es/isEmpty";
import itemsUploading from "./itemsUploading";
const itemsUpdate = ({ items = [], ifile, numParallelUpload = 1, relativePathsFolderId, }) => {
    if (isEmpty(items))
        return [];
    const { id, sessionUploadId, tooltip, status } = ifile;
    items.forEach((item) => {
        if (item.id === id && sessionUploadId === item.sessionUploadId) {
            item.status = status;
            if (!item.tooltip && tooltip && status === IFileStatus.Error) {
                item.tooltip = tooltip;
            }
        }
    });
    return itemsUploading({
        items,
        relativePathsFolderId,
        numParallelUpload,
    });
};
export default itemsUpdate;
