import * as React from "react";
import CardDraggable from "@warda/library-ui/core/CardDraggable";
import List from "./parts/List";
import MsgStopUpload from "./parts/MsgStopUpload";
import Toolbar from "./parts/Toolbar";
import { v4 as uuidv4 } from "uuid";
import { IFileStatus } from "./";
import emptyFn from "@warda/library-ui/utils/emptyFn";
import { createUseStyles } from "react-jss";
import classnames from "classnames";
const dragCls = `multiupload_drag_${uuidv4()}`;
const useStyles = createUseStyles({
    draggableUploads: {
        width: 410,
    },
});
const i18n = {
    filesUploaded: "Files uploaded",
    filesProcessed: "Files processed",
    msgStopUploadsBtnInterrupt: "Interrupt",
    msgStopUploadsBtnContinue: "Continue",
    msgStopUploadsTitle: "Stop uploads?",
    msgStopUploadsContent: "There are some uploads in progress, do you want to interrupt your current uploads?",
};
const ModalUploads = ({ className, items = [], getSignedUrl, expanded = false, onCloseToolbar, onPutS3File, onExpand, signingUrlMethod = "GET", style, uploadRequestHeaders = { "x-amz-acl": "public-read" }, onUpdateFileStatus, msgStopUpload, onContinue, onFinish = emptyFn, onStopUploads, }) => {
    const classes = useStyles({});
    React.useEffect(() => {
        const filesProcessing = !!items.find((el) => {
            const fSet = new Set([IFileStatus.Uploading, IFileStatus.Waiting]);
            return fSet.has(el.status);
        });
        if (!filesProcessing && !!onFinish && !!items.length) {
            onFinish(items);
        }
    }, [items, onFinish]);
    return (React.createElement(React.Fragment, null,
        React.createElement(CardDraggable, { dragCls: dragCls, position: {
                bottom: 25,
                right: 25,
            }, cardProps: {
                style,
                className: classnames({
                    [classes.draggableUploads]: true,
                    [className]: !!className,
                }),
            } },
            React.createElement(Toolbar, { i18n: i18n, expanded: expanded, files: items, onExpand: onExpand, onClose: onCloseToolbar, className: dragCls }),
            React.createElement(List, { expanded: expanded, uploadRequestHeaders: uploadRequestHeaders, files: items, getSignedUrl: getSignedUrl, signingUrlMethod: signingUrlMethod, onPutS3File: onPutS3File, onUpdateFileStatus: onUpdateFileStatus })),
        React.createElement(MsgStopUpload, { i18n: i18n, open: msgStopUpload, onContinue: onContinue, onStop: onStopUploads })));
};
export default ModalUploads;
