import * as React from "react";
import Divider from "@warda/library-ui/core/Divider";
import { needPagination } from "../utils";
import ViewLoadMore from "../ViewLoadMore";
import ViewLoadedCount from "../ViewLoadedCount";
import { getTheme } from "@warda/library-ui/theme";
const ViewBlockFooter = ({ type, prnt, files, filesTotal, sortKey, sortOrd, filters, filtersCondition, indexRow, indexCol, totalCols, }) => {
    const { pagination } = needPagination({
        type,
        files,
        filesTotal,
    });
    if (!pagination)
        return null;
    if (indexRow > 0)
        return null;
    if (indexCol === 0) {
        return (React.createElement(React.Fragment, null,
            React.createElement(ViewLoadedCount, { type: type, files: files, filesTotal: filesTotal, style: {
                    position: "absolute",
                    top: 0,
                    left: 15,
                    bottom: 0,
                    margin: "auto",
                    height: "fit-content",
                    paddingRight: 15,
                    backgroundColor: getTheme().colors.background,
                } }),
            React.createElement(Divider, { color: getTheme().colors.theme1, style: { marginLeft: 50 } })));
    }
    if (indexCol + 1 === totalCols) {
        return (React.createElement(React.Fragment, null,
            React.createElement(ViewLoadMore, { type: type, prnt: prnt, sortKey: sortKey, sortOrd: sortOrd, filters: filters, filtersCondition: filtersCondition, style: {
                    position: "absolute",
                    top: 0,
                    right: 8,
                    bottom: 0,
                    margin: "auto",
                } }),
            React.createElement(Divider, { color: getTheme().colors.theme1 })));
    }
    return React.createElement(Divider, { color: getTheme().colors.theme1 });
};
export default ViewBlockFooter;
