import IFileStatus from "../IFileStatus";
const itemsUploading = ({ items, numParallelUpload = 1, }) => {
    const newItems = [].concat(items);
    newItems.forEach((itemToAdd) => {
        const canOtherUpdating = newItems.filter(({ status }) => status === IFileStatus.Uploading).length <
            numParallelUpload;
        if (canOtherUpdating && itemToAdd.status === IFileStatus.Waiting) {
            itemToAdd.status = IFileStatus.Uploading;
        }
    });
    return newItems;
};
export default itemsUploading;
