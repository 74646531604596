import * as React from "react";
import { createUseStyles } from "react-jss";
import Popover from "@warda/library-ui/core/Popover";
import Text from "@warda/library-ui/core/Text";
import Divider from "@warda/library-ui/core/Divider";
import { getTheme } from "@warda/library-ui/theme";
import LogoWarda from "@warda/library-ui/core/LogoWarda/weight/medium";
import version from "../../../version.json";
import BtnLink from "./BtnLink";
const originAnchor = {
    vertical: "bottom",
    horizontal: "right",
};
const originTransf = {
    vertical: "top",
    horizontal: "right",
};
const useStyles = createUseStyles({
    window: {
        maxHeight: "90vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "stretch",
        overflow: "hidden",
        padding: 15,
    },
    row: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
    },
});
const WindowChangelog = ({ title, anchorEl, open, onClose, }) => {
    const classes = useStyles({});
    return (React.createElement(React.Fragment, null,
        React.createElement(Popover, { anchorEl: anchorEl, open: open, onClose: onClose, originAnchor: originAnchor, originTransf: originTransf, className: classes.window },
            React.createElement("div", { className: classes.row },
                React.createElement("div", { style: { flex: 1 } }),
                React.createElement(Text, { size: 4, style: { color: getTheme().colors.theme1 }, children: title }),
                React.createElement("div", { style: { flex: 1 } })),
            React.createElement("div", { className: classes.row, style: { margin: "5px 0" } },
                React.createElement("div", { style: { flex: 1 } }),
                React.createElement(Text, { style: {
                        background: getTheme().colors.grayDrawer,
                        padding: "2px 10px",
                        borderRadius: 5,
                        fontFamily: "monospace",
                    }, children: version.version }),
                React.createElement("div", { style: { flex: 1 } })),
            React.createElement("div", { className: classes.row, style: { margin: "15px 0" } },
                React.createElement("div", { style: { flex: 1 } }),
                React.createElement(Text, { style: { color: getTheme().colors.theme2 }, size: 0, children: "Powered by " }),
                React.createElement(LogoWarda, { color: getTheme().colors.theme2, width: 55, height: 10 }),
                React.createElement("div", { style: { flex: 1 } })),
            React.createElement(Divider, null),
            React.createElement("div", { style: { height: 10 } }),
            React.createElement(BtnLink, { url: "https://www.seecommerce.it", label: "Visit our website" }),
            React.createElement(BtnLink, { url: "https://www.seecommerce.it/release_note/", label: "Explore new features" }))));
};
export default WindowChangelog;
