import * as React from "react";
import { PopoverListItem, } from "@warda/library-ui/core/PopoverList";
import Text from "@warda/library-ui/core/Text";
import Divider from "@warda/library-ui/core/Divider";
import { getTheme } from "@warda/library-ui/theme";
import { Sheet } from "react-modal-sheet";
import Toolbar from "@warda/library-ui/core/Toolbar";
const SheetBottom = ({ open, onClose, title, actions }) => (React.createElement(Sheet, { isOpen: open, onClose: onClose, detent: "content-height" },
    React.createElement(Sheet.Container, null,
        React.createElement(Sheet.Header, null),
        React.createElement(Sheet.Content, null,
            !title ? null : (React.createElement(React.Fragment, null,
                React.createElement(Toolbar, null,
                    React.createElement(Text, { ellipsis: true, weight: "bolder", size: 2, children: title })),
                React.createElement(Divider, null))),
            React.createElement(Sheet.Scroller, { style: { padding: "10px 10px 40px 10px" } }, actions.map((a) => (React.createElement(PopoverListItem, { key: a.id, ...a, style: { borderRadius: getTheme().borderRadius, ...a.style }, onClose: onClose })))))),
    React.createElement(Sheet.Backdrop, { onTap: onClose })));
export default SheetBottom;
