import * as React from "react";
import { getTheme } from "@warda/library-ui/theme";
import { createUseStyles } from "react-jss";
import { CtxUserData } from "../contexts";
import emptyFn from "@warda/library-ui/utils/emptyFn";
import Toolbar from "@warda/library-ui/core/Toolbar";
import Text from "@warda/library-ui/core/Text";
import Btn from "@warda/library-ui/core/Btn";
import S3Upload from "../Uploads/utils/S3Upload";
import endpoints from "../../api/apiUrls";
import { signUrl } from "../../api/fetchesApi";
import { fetchCookieJwtWithRefreshToken } from "../../api/fetchCookieJwt";
import { RENDITIONS } from "../../interfaces";
import Popover from "@warda/library-ui/core/Popover";
import Divider from "@warda/library-ui/core/Divider";
import ProfileAvatar from "./ProfileAvatar";
import getMimeType from "../Uploads/utils/getMimeType";
import { getUserFullname } from "@warda/library-ui/utils/getUser";
const originAnchor = {
    vertical: "bottom",
    horizontal: "right",
};
const originTransf = {
    vertical: "top",
    horizontal: "right",
};
const width = 300;
const useStyles = createUseStyles({
    window: {
        maxHeight: "90vh",
        width,
        minWidth: width,
        maxWidth: width,
        display: "flex",
        flexDirection: "column",
        alignItems: "stretch",
        textAlign: "center",
    },
    header: {
        padding: "0 30px 0 20px",
    },
});
const WindowProfile = ({ open, anchorEl, onClose, onModalLogout, }) => {
    const classes = useStyles({});
    const S3UploadRef = React.useRef(null);
    const currUser = React.useContext(CtxUserData);
    const { profileData, roles } = currUser;
    const { email, picture } = profileData;
    const [file, setFile] = React.useState(null);
    const [progress, setProgress] = React.useState(0);
    const [removePhoto, setRemovePhoto] = React.useState(false);
    const [picturePreview, setPicturePreview] = React.useState(picture);
    const onPopoverClose = React.useCallback(() => {
        if (progress === 0 || progress === 100) {
            onClose();
        }
    }, [onClose, progress]);
    const onFinishS3Put = React.useCallback(async ({ fileId }) => {
        await fetchCookieJwtWithRefreshToken({
            url: endpoints.profilePictureConfirm.url(fileId),
            method: endpoints.profilePictureConfirm.method,
        });
        await fetchCookieJwtWithRefreshToken({
            url: endpoints.putUserProfile.url(),
            method: endpoints.putUserProfile.method,
            jsonBody: {
                picture: endpoints.downloadMediaSC.url(fileId, {
                    rendition: RENDITIONS.image_s,
                }),
            },
        });
        setFile(null);
    }, []);
    const getSignedUrl = React.useCallback(async (item, callback) => {
        if (file) {
            const { name, type } = file;
            const { url, method } = endpoints.profilePictureUpload;
            const res = await signUrl({
                url: url(),
                method,
                filename: name,
                mimeType: getMimeType(name, type),
            });
            callback(res);
        }
    }, [file]);
    const onProgress = React.useCallback((a) => {
        setProgress(a);
    }, []);
    const onReplacePhoto = React.useCallback((event) => {
        setFile(event.target.files[0]);
    }, []);
    const onRemovePhoto = React.useCallback(() => {
        setRemovePhoto(true);
        setProgress(100);
    }, []);
    React.useEffect(() => {
        (async () => {
            if (removePhoto) {
                const { url, method } = endpoints.putUserProfile;
                setRemovePhoto(false);
                await fetchCookieJwtWithRefreshToken({
                    url: url(),
                    method,
                    jsonBody: { picture: "" },
                });
            }
        })();
    }, [removePhoto]);
    React.useEffect(() => {
        if (file) {
            S3UploadRef.current = new S3Upload({
                file,
                mimeType: file.type,
                getSignedUrl,
                server: undefined,
                onFinishS3Put,
                onProgress,
                onError: emptyFn,
                onAbort: emptyFn,
                signingUrlMethod: "GET",
                uploadRequestHeaders: {},
                contentDisposition: "auto",
            });
        }
    }, [file, getSignedUrl, onFinishS3Put, onProgress]);
    React.useEffect(() => {
        setPicturePreview(picture);
    }, [picture]);
    return (React.createElement(Popover, { open: open, onClose: onPopoverClose, anchorEl: anchorEl, originAnchor: originAnchor, originTransf: originTransf, className: classes.window },
        React.createElement(Toolbar, { className: classes.header },
            React.createElement(Text, { weight: "bolder", children: "Profile" })),
        React.createElement(Divider, null),
        React.createElement("br", null),
        React.createElement(ProfileAvatar, { srcUrl: picturePreview, progress: progress, onReplacePhoto: onReplacePhoto, onRemovePhoto: onRemovePhoto }),
        React.createElement("br", null),
        React.createElement(Text, { weight: "bolder", size: 3, children: getUserFullname(currUser) }),
        React.createElement(Text, { children: email }),
        React.createElement(Text, { weight: "bolder", style: { color: getTheme().colors.theme1 }, children: roles.map((r) => r.roleLabel).join(", ") }),
        React.createElement("br", null),
        React.createElement(Toolbar, null,
            React.createElement("div", { style: { flex: 1 } }),
            React.createElement(Btn, { variant: "bold", color: getTheme().colors.msgFail, label: "LOGOUT", icon: "exit_to_app", onClick: onModalLogout }))));
};
export default WindowProfile;
