import { INDEX_NAME, KEY_ASSIGNMENTS, KEY_ATTRIBUTE_SETS, KEY_ENTITY_ID, KEY_ENTITY_STRUCTURE_ID, KEY_MEDIA, KEY_PUBLC_MEDIA, KEY_READY_MEDIA, KEY_ROOT_DOCUMENT_ID, KEY_VIEW_CHECK, KEY_VIEW_DATA, KEY_VIEW_STATUS, } from "../../../constantsKey";
import { search } from "../../../api/fetchesApi";
import { mergeProductDatas, getDataDictionaries } from "./";
import { fetchCookieJwtWithRefreshToken } from "../../../api/fetchCookieJwt";
import apiUrls from "../../../api/apiUrls";
import { Service } from "@warda/library-ui/interfaces";
import { TypeCell } from "@warda/library-ui/core/StickyGrid";
import { getAttributeId } from "../getAttributeKey";
import { MAX_PAGINATION } from "../../../constants";
const getMSearchBody = (index, ids, _source) => ({
    index,
    body: {
        track_total_hits: true,
        from: 0,
        size: MAX_PAGINATION,
        sort: [],
        query: { terms: { _id: ids } },
        _source,
    },
});
const getMSearchItems = (elasticSrc) => {
    if (!!elasticSrc.error)
        return [];
    return elasticSrc.hits.hits.map((o) => ({
        id: o._id,
        data: o._source,
    }));
};
const msearch = async ({ itemsId, rootsId, loadMedia, loadRoots, }) => {
    const res = {
        rootsTabular: [],
        itemsTabularRoot: [],
        itemsMedia: [],
        itemsReady: [],
        itemsPublc: [],
    };
    if (!loadRoots)
        return res;
    const jsonBody = [
        getMSearchBody(INDEX_NAME.TABULAR, rootsId, [KEY_ATTRIBUTE_SETS]),
        getMSearchBody(INDEX_NAME.TABULARROOT, rootsId, undefined),
    ];
    if (loadMedia) {
        jsonBody.push(getMSearchBody(INDEX_NAME.MEDIA, itemsId, [KEY_MEDIA]));
        jsonBody.push(getMSearchBody(INDEX_NAME.READY, itemsId, [KEY_READY_MEDIA]));
        jsonBody.push(getMSearchBody(INDEX_NAME.PUBLC, itemsId, [KEY_PUBLC_MEDIA]));
    }
    const { url, method } = apiUrls.msearch;
    const { responses } = await fetchCookieJwtWithRefreshToken({
        url: url(),
        method,
        jsonBody,
    });
    res.rootsTabular = getMSearchItems(responses[0]);
    res.itemsTabularRoot = getMSearchItems(responses[1]);
    if (loadMedia) {
        res.itemsMedia = getMSearchItems(responses[2]);
        res.itemsReady = getMSearchItems(responses[3]);
        res.itemsPublc = getMSearchItems(responses[4]);
    }
    return res;
};
const findData = (a, keyValue) => {
    var _a;
    const res = (_a = a.find((i) => i.id === keyValue)) === null || _a === void 0 ? void 0 : _a.data;
    return res || {};
};
const searchHasAggs = (searchEs) => {
    return !!searchEs && !!searchEs.aggs;
};
const searchHasSource = (searchEs) => {
    return !!searchEs && !!searchEs._source && !!searchEs._source.length;
};
const decorateSource = (searchEs) => {
    if (!searchHasSource(searchEs))
        return [];
    const sourceNew = new Set(searchEs._source);
    sourceNew.add(KEY_ENTITY_ID);
    sourceNew.add(KEY_ROOT_DOCUMENT_ID);
    sourceNew.add(KEY_ATTRIBUTE_SETS);
    sourceNew.add(KEY_ENTITY_STRUCTURE_ID);
    if (sourceNew.has(KEY_MEDIA)) {
        sourceNew.add(KEY_VIEW_DATA);
        sourceNew.add(KEY_VIEW_CHECK);
        sourceNew.add(KEY_VIEW_STATUS);
        sourceNew.add(KEY_ASSIGNMENTS);
    }
    return Array.from(sourceNew);
};
const isLoadDicts = (searchEs, columns) => {
    if (searchHasAggs(searchEs))
        return false;
    if (searchHasSource(searchEs)) {
        return !!searchEs._source.some((key) => {
            const { attrId } = getAttributeId(key);
            const column = columns.find((c) => c.id === attrId);
            if (!column)
                return undefined;
            const type = column.type;
            const dict = new Set([
                TypeCell.DictionaryEntry,
                TypeCell.DictionaryEntries,
            ]);
            return dict.has(type);
        });
    }
    return true;
};
const isLoadMedia = (searchEs) => {
    if (searchHasAggs(searchEs))
        return false;
    if (searchHasSource(searchEs)) {
        return searchEs._source.some((k) => k === KEY_MEDIA);
    }
    return true;
};
const isLoadRoots = (searchEs) => {
    if (searchHasAggs(searchEs))
        return false;
    if (searchHasSource(searchEs)) {
        return searchEs._source.join() !== [KEY_ENTITY_ID].join();
    }
    return true;
};
const searchEsItems = async ({ searchEs, columns, categories, }) => {
    // NOTE qui non va nessun try catch
    // l'errore va gestito caso per caso
    const res = await search({
        searchEs: { ...searchEs, _source: decorateSource(searchEs) },
        getUrl: (url) => url(INDEX_NAME.TABULAR, Service.SC),
    });
    const { items: itemsTabular, itemsTotal, aggregations } = res;
    if (!itemsTabular.length)
        return res;
    const loadDicts = isLoadDicts(searchEs, columns);
    const loadMedia = isLoadMedia(searchEs);
    const loadRoots = isLoadRoots(searchEs);
    const itemsId = itemsTabular.map((x) => x.data[KEY_ENTITY_ID]);
    const rootsId = itemsTabular.map((x) => x.data[KEY_ROOT_DOCUMENT_ID]);
    const [{ rootsTabular, itemsTabularRoot, itemsReady, itemsMedia, itemsPublc: itemsPublications, }, dictionaries,] = await Promise.all([
        msearch({
            itemsId,
            rootsId,
            loadMedia,
            loadRoots,
        }),
        getDataDictionaries(itemsTabular, columns, !loadDicts),
    ]);
    const items = itemsTabular.map(({ id, data }) => {
        const rootId = data[KEY_ROOT_DOCUMENT_ID];
        const rootItem = findData(rootsTabular, rootId);
        const tabularroot = findData(itemsTabularRoot, rootId);
        const ready = findData(itemsReady, id);
        const media = findData(itemsMedia, id);
        const publications = findData(itemsPublications, id);
        return mergeProductDatas({
            tabular: data,
            tabularroot,
            ready,
            media,
            publications,
            dictionaries,
            attributeSets: rootItem[KEY_ATTRIBUTE_SETS] || [],
            columns,
            categories,
            businessIds: [],
        });
    });
    return {
        aggregations,
        itemsTotal,
        items,
    };
};
export default searchEsItems;
