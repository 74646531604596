import * as React from "react";
import ViewList from "@warda/library-ui/core/View/ViewList";
import { FileType, } from "../../../../interfaces";
import ViewListAssetMedia from "./ViewListAssetMedia";
import ViewListAssetFolder from "./ViewListAssetFolder";
import { itemsTypeSort, needFooter, needHeader } from "../utils";
import ViewListHeader from "./ViewListHeader";
import ViewListFooter from "./ViewListFooter";
import { isMobile } from "@warda/library-ui/utils/deviceUtils";
import { ATTRIBUTES } from "../../constants";
import Btn from "@warda/library-ui/core/Btn";
const itemHeight = () => 50;
const ViewListAssetUnknown = ({ id, color }) => {
    return React.createElement(Btn, { color: color, label: String(id) });
};
const View = ({ color, searchTime, prnt, files, filesTotal, filesSlcId, loading, onClick, sortKey, sortOrd, placeholderIcon, placeholderLabel, filters, filtersCondition, }) => {
    const itemRender = React.useCallback((p) => {
        switch (p.type) {
            case FileType.FOLDER:
                return React.createElement(ViewListAssetFolder, { ...p, loading: loading });
            case FileType.MEDIA:
                return React.createElement(ViewListAssetMedia, { ...p, loading: loading });
            default:
                return React.createElement(ViewListAssetUnknown, { ...p });
        }
    }, [loading]);
    const itemHeader = React.useCallback(({ type, indexRow, totalRows }) => (React.createElement(ViewListHeader, { type: type, filesTotal: filesTotal, indexRow: indexRow, totalRows: totalRows })), [filesTotal]);
    const itemFooter = React.useCallback(({ type, indexRow, totalRows }) => (React.createElement(ViewListFooter, { type: type, files: files, filesTotal: filesTotal, sortKey: sortKey, sortOrd: sortOrd, filters: filters, filtersCondition: filtersCondition, indexRow: indexRow, totalRows: totalRows, prnt: prnt })), [files, filesTotal, filters, filtersCondition, prnt, sortKey, sortOrd]);
    return (React.createElement(ViewList, { color: color, itemHeight: itemHeight, itemRender: itemRender, itemHeader: itemHeader, itemFooter: itemFooter, needHeader: needHeader, needFooter: needFooter(files, filesTotal), needToolbar: false, items: files, itemsSelectedId: filesSlcId, itemsTypeSort: itemsTypeSort, loading: loading, onClick: onClick, placeholderIcon: placeholderIcon, placeholderLabel: placeholderLabel, columns: isMobile() ? undefined : ATTRIBUTES, columnsSortId: sortKey, columnsSortOrder: sortOrd, resetScrollbar: searchTime }));
};
export default View;
