import { FileType, } from "../../interfaces";
import { Initialize, ISortOrder } from "@warda/library-ui/interfaces";
import { SegmentedViewType } from "@warda/library-ui/core/SegmentedView";
import { FiltersCondition } from "@warda/library-ui/core/Filter";
import { clearFiltersAttr } from "../PanelFilters";
import apiUrls from "../../api/apiUrlsAnonymous";
import { directDownload } from "../../utils/directDownloadMedia";
import updateFilesSlcId from "../AreaMediaLibrary/AreaFiles/utils/updateFilesSlcId";
import { isMobile } from "@warda/library-ui/utils/deviceUtils";
import { wsItemError, wsItemFinish, wsItemUpdate } from "../websocketUpdates";
const getPageHrchId = ({ files, pageHrchId }) => {
    let hrch = pageHrchId;
    if (!hrch && files.length === 1) {
        hrch = files[0].data.hierarchyId;
    }
    return hrch;
};
export var ACT_VPORT;
(function (ACT_VPORT) {
    ACT_VPORT["INIT_SEARCH_STARTED"] = "INIT_SEARCH_STARTED";
    ACT_VPORT["INIT_SEARCH_LOADING"] = "INIT_SEARCH_LOADING";
    ACT_VPORT["INIT_SEARCH_RESULT"] = "INIT_SEARCH_RESULT";
    ACT_VPORT["LOADED_MORE_FILES"] = "LOADED_MORE_FILES";
    ACT_VPORT["DOWNLOAD_SINGLE_MEDIA"] = "DOWNLOAD_SINGLE_MEDIA";
    ACT_VPORT["DOWNLOADS_TO_ADD"] = "DOWNLOADS_TO_ADD";
    ACT_VPORT["DOWNLOADS_ERROR"] = "DOWNLOADS_ERROR";
    ACT_VPORT["DOWNLOADS_UPDATE"] = "DOWNLOADS_UPDATE";
    ACT_VPORT["DOWNLOADS_FINISH"] = "DOWNLOADS_FINISH";
    ACT_VPORT["DOWNLOADS_REMOVE"] = "DOWNLOADS_REMOVE";
    ACT_VPORT["SELECT_FILE_CLICK"] = "SELECT_FILE_CLICK";
    ACT_VPORT["SELECT_FILE_CHECK"] = "SELECT_FILE_CHECK";
    ACT_VPORT["DESELECT_FILES"] = "DESELECT_FILES";
    ACT_VPORT["SORT"] = "SORT";
    ACT_VPORT["VIEW"] = "VIEW";
    ACT_VPORT["NAVIGATION"] = "NAVIGATION";
    ACT_VPORT["BREADCRUMB"] = "BREADCRUMB";
    ACT_VPORT["DIALOG_DOWNLOAD"] = "DIALOG_DOWNLOAD";
    ACT_VPORT["DIALOG_DETAIL"] = "DIALOG_DETAIL";
    ACT_VPORT["DIALOG_VIEWER"] = "DIALOG_VIEWER";
})(ACT_VPORT || (ACT_VPORT = {}));
export const reducerInitState = {
    pageHrchId: undefined,
    initSearch: Initialize.START,
    initMsgErr: undefined,
    folderData: undefined,
    folderPath: [],
    files: [],
    filesTotal: {
        [FileType.FOLDER]: 0,
        [FileType.MEDIA]: 0,
        [FileType.TOUCHPOINT]: 0,
    },
    filesSlcId: [],
    filters: [],
    filtersCondition: FiltersCondition.AND,
    sortKey: "name",
    sortOrd: ISortOrder.ASC,
    view: SegmentedViewType.LIST,
    downloads: [],
    dialogDownload: false,
    dialogDetail: false,
    dialogViewer: false,
};
const reducer = (state, action) => {
    const newState = { ...state };
    switch (action.type) {
        case ACT_VPORT.INIT_SEARCH_STARTED:
            newState.initSearch = Initialize.START;
            newState.initMsgErr = reducerInitState.initMsgErr;
            newState.filters = reducerInitState.filters;
            return newState;
        case ACT_VPORT.INIT_SEARCH_LOADING:
            newState.initSearch = Initialize.LOADING;
            newState.initMsgErr = reducerInitState.initMsgErr;
            newState.filesSlcId = reducerInitState.filesSlcId;
            return newState;
        case ACT_VPORT.INIT_SEARCH_RESULT: {
            const result = action.result;
            newState.initSearch = result.initSearch;
            newState.initMsgErr = result.error;
            newState.folderData = result.folderData;
            newState.folderPath = result.folderPath;
            newState.files = result.files;
            newState.filesTotal = result.filesTotal;
            newState.pageHrchId = getPageHrchId(newState);
            return newState;
        }
        case ACT_VPORT.LOADED_MORE_FILES:
            newState.files = newState.files.concat(action.files);
            return newState;
        case ACT_VPORT.DOWNLOAD_SINGLE_MEDIA: {
            const { files, filesSlcId } = newState;
            const file = files.find(({ id }) => id === filesSlcId[0]);
            const fileData = file.data;
            const { fileId, name } = fileData;
            const srcUrl = apiUrls.mediaDownload.url(fileId, undefined, name);
            directDownload(srcUrl);
            return state;
        }
        case ACT_VPORT.DOWNLOADS_TO_ADD: {
            const { correlationId, filename } = action.payload;
            const newDownloads = Array.from(newState.downloads);
            newDownloads.push({
                id: correlationId,
                loading: true,
                name: filename,
                url: "",
            });
            newState.downloads = newDownloads;
            return newState;
        }
        case ACT_VPORT.DOWNLOADS_ERROR: {
            const newDownloads = wsItemError(newState.downloads, action.payload);
            // nothing to update - return state to avoid rerender
            if (!newDownloads.length)
                return state;
            newState.downloads = newDownloads;
            return newState;
        }
        case ACT_VPORT.DOWNLOADS_UPDATE: {
            const newDownloads = wsItemUpdate(newState.downloads, action.payload);
            // nothing to update - return state to avoid rerender
            if (!newDownloads.length)
                return state;
            newState.downloads = newDownloads;
            return newState;
        }
        case ACT_VPORT.DOWNLOADS_FINISH: {
            const newDownloads = wsItemFinish(newState.downloads, action.payload);
            // nothing to update - return state to avoid rerender
            if (!newDownloads.length)
                return state;
            newState.downloads = newDownloads;
            return newState;
        }
        case ACT_VPORT.DOWNLOADS_REMOVE: {
            const newDownloads = Array.from(newState.downloads);
            const file = action.file;
            const { id, url } = file;
            const index = newDownloads.findIndex((i) => i.id === id);
            if (index !== -1) {
                const anchor = document.createElement("a");
                document.body.appendChild(anchor);
                anchor.href = url;
                anchor.click();
                document.body.removeChild(anchor);
                newDownloads.splice(index, 1);
            }
            newState.downloads = newDownloads;
            return newState;
        }
        case ACT_VPORT.SELECT_FILE_CLICK: {
            const { files, filesSlcId } = newState;
            const { id, keyDownCtrl, keyDownMeta, detail } = action;
            newState.filesSlcId = updateFilesSlcId({
                files,
                filesSlcId,
                id,
                keyDownCtrl,
                keyDownMeta,
            });
            if (detail === true) {
                newState.dialogDetail = true;
            }
            return newState;
        }
        case ACT_VPORT.SELECT_FILE_CHECK: {
            const { files, filesSlcId } = newState;
            const { id, keyDownCtrl, keyDownMeta } = action;
            newState.filesSlcId = updateFilesSlcId({
                files,
                filesSlcId,
                id,
                keyDownCtrl,
                keyDownMeta,
            });
            return newState;
        }
        case ACT_VPORT.DESELECT_FILES:
            newState.filesSlcId = reducerInitState.filesSlcId;
            return newState;
        case ACT_VPORT.SORT:
            newState.sortKey = action.key || newState.sortKey;
            newState.sortOrd = action.ord || newState.sortOrd;
            newState.initSearch = Initialize.START;
            return newState;
        case ACT_VPORT.VIEW:
            newState.view = action.view;
            return newState;
        case ACT_VPORT.NAVIGATION: {
            const { id, name } = action;
            const newPath = Array.from(newState.folderPath);
            if (!!name) {
                newPath.push({ id, name });
            }
            else {
                newPath.pop();
            }
            newState.files = reducerInitState.files;
            newState.filesTotal = reducerInitState.filesTotal;
            newState.filesSlcId = reducerInitState.filesSlcId;
            newState.filters = clearFiltersAttr(newState.filters);
            newState.initSearch = Initialize.START;
            newState.folderPath = newPath;
            return newState;
        }
        case ACT_VPORT.BREADCRUMB: {
            const pathId = action.pathId;
            let newPath = Array.from(newState.folderPath);
            const index = newPath.findIndex((p) => p.id === pathId);
            if (index !== -1) {
                newPath = newPath.slice(0, index + 1);
            }
            newState.files = reducerInitState.files;
            newState.filesTotal = reducerInitState.filesTotal;
            newState.filesSlcId = reducerInitState.filesSlcId;
            newState.filters = clearFiltersAttr(newState.filters);
            newState.initSearch = Initialize.START;
            newState.folderPath = newPath;
            return newState;
        }
        case ACT_VPORT.DIALOG_DOWNLOAD: {
            const { open, fileId } = action;
            if (open !== undefined) {
                newState.dialogDownload = action.open;
            }
            else {
                newState.dialogDownload = !newState.dialogDownload;
            }
            if (fileId !== undefined) {
                newState.filesSlcId = [fileId];
            }
            return newState;
        }
        case ACT_VPORT.DIALOG_DETAIL:
            newState.dialogDetail = !newState.dialogDetail;
            newState.dialogViewer = reducerInitState.dialogViewer;
            if (isMobile()) {
                newState.filesSlcId = reducerInitState.filesSlcId;
            }
            return newState;
        case ACT_VPORT.DIALOG_VIEWER:
            newState.dialogViewer = !newState.dialogViewer;
            return newState;
        default:
            console.error(`no action ${action.type}`);
            return state;
    }
};
export default reducer;
