import * as React from "react";
import Btn from "@warda/library-ui/core/Btn";
import IFileStatus from "../IFileStatus";
import Spinner from "./Spinner";
import { getTheme } from "@warda/library-ui/theme";
const IconStatusFile = ({ fileStatus, tooltip, spinnerProgress = 0, spinner = false, }) => {
    const i = {
        small: true,
        variant: "bold",
        tooltip,
        style: { margin: "0 4px 0 6px" },
    };
    if (spinner)
        return React.createElement(Spinner, null);
    switch (fileStatus) {
        case IFileStatus.Uploading:
            return React.createElement(Spinner, { value: spinnerProgress });
        case IFileStatus.Completed:
            i.color = getTheme().colors.msgSucc;
            return React.createElement(Btn, { ...i, icon: "check" });
        case IFileStatus.Error:
            i.color = getTheme().colors.msgFail;
            return React.createElement(Btn, { ...i, icon: "warning" });
        case IFileStatus.Abort:
            return React.createElement(Btn, { ...i, icon: "cancel" });
        case IFileStatus.Waiting:
            return React.createElement(Spinner, { value: 100 });
        default:
            return null;
    }
};
export default IconStatusFile;
