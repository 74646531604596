import itemsUpdate from "./itemsUpdate";
import itemsAddAndUploading from "./itemsAddAndUploading";
import { maxSize } from "@warda/library-ui/utils/mimeTypes";
import { MAX_UPLOAD } from "../../../constants";
export var ACTIONS;
(function (ACTIONS) {
    ACTIONS["RESET"] = "RESET";
    ACTIONS["SET_ITEMS"] = "SET_ITEMS";
    ACTIONS["ADD_ITEMS"] = "ADD_ITEMS";
    ACTIONS["MSG_CLOSE"] = "MSG_CLOSE";
    ACTIONS["MSG_OPEN"] = "MSG_OPEN";
    ACTIONS["EXPANDED"] = "EXPANDED";
    ACTIONS["SET_FILES_DUPLICATED"] = "SET_FILES_DUPLICATED";
})(ACTIONS || (ACTIONS = {}));
export const reducerInitState = {
    expanded: true,
    items: [],
    msgStopUpload: false,
};
export const reducer = (state, action) => {
    const newState = { ...state };
    switch (action.type) {
        case ACTIONS.EXPANDED:
            newState.expanded = !newState.expanded;
            return newState;
        case ACTIONS.MSG_OPEN:
            newState.msgStopUpload = true;
            return newState;
        case ACTIONS.MSG_CLOSE:
            newState.msgStopUpload = false;
            return newState;
        case ACTIONS.SET_ITEMS:
            newState.items = itemsUpdate({
                items: newState.items,
                ifile: action.ifile,
                numParallelUpload: MAX_UPLOAD,
            });
            return newState;
        case ACTIONS.ADD_ITEMS:
            newState.items = itemsAddAndUploading({
                items: newState.items,
                filesToAdd: action.uploads,
                maxSize,
                numParallelUpload: MAX_UPLOAD,
            });
            return newState;
        case ACTIONS.RESET:
            return reducerInitState;
        default:
            return newState;
    }
};
