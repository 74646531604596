import * as React from "react";
import AreaContext from "./AreaContext";
import { createUseStyles } from "react-jss";
import reducer, { reducerInitState, ACT_VPORT } from "./reducer";
import useSearchEs from "./useSearchEs";
import { Initialize } from "@warda/library-ui/interfaces";
import Placeholder from "@warda/library-ui/core/Placeholder";
import View from "./View";
import ToolbarActions from "./ToolbarActions";
import { last } from "lodash-es";
import { SnackbarProvider } from "notistack";
import SnackbarMessage from "../SnackbarMessage";
import DialogDetail from "./DialogDetail";
import DialogViewer from "./DialogViewer";
import DialogConfirmDownload from "./DialogConfirmDownload";
import { getTenantId, HTML_ID_DRAWERS } from "./constants";
import { getTheme } from "@warda/library-ui/theme";
import setThemeDefault from "../../utils/setThemeDefault";
import { openWebSocket, registerWebSocketCallback, unregisterWebSocketCallback, } from "../webSocket";
import webSocketCallbacks from "./websocketCallbacks";
import Toolbar from "@warda/library-ui/core/Toolbar";
import BtnRefresh from "./Buttons/BtnRefresh";
import TenantLogo from "./TenantLogo";
import BtnDetail from "./Buttons/BtnDetail";
import { isMobile } from "@warda/library-ui/utils/deviceUtils";
import BtnDownloads from "./Buttons/BtnDownloads";
import { PRODUCT_ID } from "../../constantsKey";
const getPrnt = (folderPath) => {
    try {
        const elasticId = last(folderPath).id;
        const folderId = parseInt(elasticId.split("_")[1]);
        return folderId;
    }
    catch {
        return undefined;
    }
};
const useStyles = createUseStyles({
    main: {
        height: "100vh",
        width: "100vw",
        position: "relative",
        display: "flex",
        flexDirection: "row",
        alignItems: "stretch",
        padding: isMobile() ? 0 : "0 15px 15px 15px",
        boxSizing: "border-box",
        backgroundColor: getTheme().colors.grayDrawer,
    },
    area: {
        flex: 1,
        position: "relative",
        display: "flex",
        flexDirection: "column",
        alignItems: "stretch",
    },
    areaContent: {
        position: "relative",
        flex: 1,
        display: "flex",
        flexDirection: "column",
        alignItems: "stretch",
        borderRadius: isMobile() ? undefined : getTheme().borderRadius,
        border: isMobile()
            ? undefined
            : `1px solid ${getTheme().colors.grayBorder}`,
        backgroundColor: getTheme().colors.background,
        overflow: "hidden",
    },
});
const Area = () => {
    const classes = useStyles({});
    const [state, dispatch] = React.useReducer(reducer, reducerInitState);
    const { pageHrchId, initSearch, initMsgErr, folderPath, files, filesSlcId, filesTotal, filters, filtersCondition, sortKey, sortOrd, view, downloads, dialogDownload, dialogDetail, dialogViewer, } = state;
    const tenantId = getTenantId();
    const prnt = getPrnt(folderPath);
    const { searchTime, setSearchTime, searchRes } = useSearchEs({
        prnt,
        sortKey,
        sortOrd,
        filters,
        filtersCondition,
    });
    const loading = initSearch === Initialize.START || initSearch === Initialize.LOADING;
    React.useEffect(() => {
        setThemeDefault();
    }, []);
    React.useEffect(() => {
        if (initSearch === Initialize.START) {
            dispatch({ type: ACT_VPORT.INIT_SEARCH_LOADING });
            setSearchTime(new Date().getTime());
        }
    }, [initSearch, setSearchTime]);
    // pass search res to reducer
    React.useEffect(() => {
        if (!!searchRes.result) {
            document.title = "SharePage";
            dispatch({
                type: ACT_VPORT.INIT_SEARCH_RESULT,
                result: searchRes.result,
            });
        }
    }, [searchRes.result]);
    // Avvio websocket
    React.useEffect(() => {
        const urlWebSocket = `${process.env.WEBSOCKET_URL}/anonymous-socket/${PRODUCT_ID}/${tenantId}`;
        openWebSocket(urlWebSocket, true);
    }, [tenantId]);
    // add websocket listener
    React.useEffect(() => {
        webSocketCallbacks.forEach((x) => {
            registerWebSocketCallback({
                id: x.id,
                callback: x.callback,
                dispatch,
            });
        });
        return () => {
            webSocketCallbacks.forEach((x) => {
                unregisterWebSocketCallback(x.id);
            });
        };
    }, []);
    return (React.createElement(SnackbarProvider, { preventDuplicate: true, maxSnack: 3, Components: {
            default: SnackbarMessage,
            success: SnackbarMessage,
            error: SnackbarMessage,
            warning: SnackbarMessage,
            info: SnackbarMessage,
        } },
        React.createElement(AreaContext, { dispatch: dispatch },
            React.createElement("div", { className: classes.main, id: HTML_ID_DRAWERS },
                React.createElement("div", { className: classes.area },
                    React.createElement(Toolbar, null,
                        React.createElement(TenantLogo, { style: { marginLeft: 10 } }),
                        React.createElement("div", { style: { flex: 1 } }),
                        React.createElement(BtnDownloads, { downloads: downloads }),
                        React.createElement(BtnRefresh, null),
                        dialogDetail || isMobile() ? null : React.createElement(BtnDetail, null)),
                    React.createElement("div", { className: classes.areaContent }, initSearch === Initialize.FAIL ? (React.createElement(Placeholder, { icon: "live_help", label: "You can't access this page" })) : (React.createElement(React.Fragment, null,
                        React.createElement(ToolbarActions, { loading: loading, filesSlcId: filesSlcId, folderPath: folderPath, sortKey: sortKey, sortOrd: sortOrd, view: view }),
                        React.createElement(View, { searchTime: searchTime, error: initMsgErr, prnt: prnt, view: view, files: files, filesSlcId: filesSlcId, filesTotal: filesTotal, loading: loading, sortKey: sortKey, sortOrd: sortOrd, filters: filters, filtersCondition: filtersCondition })))))),
            React.createElement(DialogConfirmDownload, { open: dialogDownload, files: files, filesSlcId: filesSlcId, pageHrchId: pageHrchId }),
            React.createElement(DialogDetail, { open: dialogDetail, file: files.find((f) => f.id === last(filesSlcId)) }),
            React.createElement(DialogViewer, { open: dialogViewer, file: files.find((f) => f.id === last(filesSlcId)) }))));
};
export default Area;
