import IFileStatus from "../IFileStatus";
import bytesFormat from "@warda/library-ui/utils/bytesFormat";
const itemsAdd = ({ items, filesToAdd, maxSize }) => {
    const newItems = [].concat(items);
    filesToAdd.forEach((fileToAdd) => {
        const { file } = fileToAdd;
        const maxSizeValue = maxSize(file);
        const b1 = bytesFormat(file.size);
        const b2 = bytesFormat(maxSizeValue);
        const exceedSize = file.size > maxSizeValue;
        if (maxSizeValue !== -1 && exceedSize) {
            fileToAdd.status = IFileStatus.Error;
            fileToAdd.tooltip = `The ${b1} size of the file is greater than the maximum allowed (${b2})`;
        }
        newItems.push(fileToAdd);
    });
    return newItems;
};
export default itemsAdd;
