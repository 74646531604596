import * as React from "react";
import { ACT_VPORT } from "../reducer";
import { CtxDispatchViewport } from "../contexts";
import Btn from "@warda/library-ui/core/Btn";
import SegmentedView, { SegmentedViewType, VIEW_ICON, } from "@warda/library-ui/core/SegmentedView";
import { isMobile } from "@warda/library-ui/utils/deviceUtils";
const BtnView = ({ className, style, view }) => {
    const dispatchViewport = React.useContext(CtxDispatchViewport);
    const newView = view === SegmentedViewType.BLOCK
        ? SegmentedViewType.LIST
        : SegmentedViewType.BLOCK;
    const icon = VIEW_ICON[newView];
    const cbOnClick = React.useCallback(() => {
        const newView = view === SegmentedViewType.BLOCK
            ? SegmentedViewType.LIST
            : SegmentedViewType.BLOCK;
        dispatchViewport({ type: ACT_VPORT.VIEW, view: newView });
    }, [dispatchViewport, view]);
    const cbOnChange = React.useCallback((view) => {
        dispatchViewport({ type: ACT_VPORT.VIEW, view });
    }, [dispatchViewport]);
    if (isMobile()) {
        return (React.createElement(Btn, { className: className, style: style, tooltip: "Change view", icon: icon, onClick: cbOnClick }));
    }
    return (React.createElement(SegmentedView, { style: { margin: "0px 2px" }, value: view, options: [SegmentedViewType.LIST, SegmentedViewType.BLOCK], onChange: cbOnChange }));
};
export default BtnView;
